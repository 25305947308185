@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body {
  width: 100%;
  height: 100%;
  --custom-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --custom-bg-color: #0d0d0d;
  --custom-panel-color: #222;
  --custom-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
  --custom-color: #fff;
  --custom-color-brand: #24b47e;
  --custom-color-secondary: #666;
  --custom-border: 1px solid #333;
  --custom-border-radius: 5px;
  --custom-spacing: 5px;
  font-family: var(--custom-font-family);
  background-color: rgb(0, 0, 0) !important;
  background: linear-gradient(135deg, rgba(139, 0, 139, 0.2), rgba(199, 21, 133, 0.2), rgba(153, 50, 204, 0.2));
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto; 
}

* {
  color: var(--custom-color);
  font-family: var(--custom-font-family);
  box-sizing: border-box;
}

html,
body,
#__next {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.ant-modal-content {
  background-color: #000 !important;
}

.ant-modal-header {
  background-color: #000 !important;
}

.ant-modal-footer {
  position: relative;
  padding: 1vw 2vw;
  text-align: right;
  background: transparent;
  border-radius: 0 0 0.5vw 0.5vw;
}

.ant-modal-footer::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, purple, lightgoldenrodyellow);
  border-radius: 0.5vw 0.5vw 0 0;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #0d0d0d; 
}

*::-webkit-scrollbar-thumb {
  background-color: #ff1493; 
  border-radius: 20px;
  border: 3px solid #0d0d0d; 
}

* {
  scrollbar-width: thin;
  scrollbar-color: #ff1493 #0d0d0d;
}
